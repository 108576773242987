import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout/layout'

const VinoPage = () => (
  <Layout>
    <h1>Corazón Loco Blanco</h1>
    <h2>D.O. Manchuela </h2>
    <p>Vino blanco joven</p>
    <p>Vino estrella de la Bodega. Muy aromático, donde predominan aromas a frutas tropicales. Un sabor intenso, fresco y largo.</p>
    <Link to="/vinos">Back</Link>
  </Layout>
)

export default VinoPage
